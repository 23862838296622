import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  status: 'idle',
  error: null,
  searchUrl: '',
  message: ''
};
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    startedLoading: state => {
      state.status = 'loading';
      state.error = null;
      state.message = '';
      state.searchUrl = '';
    },
    newSearchUrl: (state, action) => {
      state.searchUrl = action.payload;
    },
    stoppedLoading: state => {
      state.status = 'idle';
    },
    newError: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
      state.message = '';
    },
    newMessage: (state, action) => {
      state.status = 'succeeded';
      state.message = action.payload;
      state.error = null;
    }
  }
});
export const {
  startedLoading,
  newSearchUrl,
  stoppedLoading,
  newMessage,
  newError
} = searchSlice.actions;
const searchReducer = searchSlice.reducer;
export default searchReducer;
export const fetchUrl = url => async dispatch => {
  dispatch(startedLoading());
  dispatch(newSearchUrl(url));
  axios.post('https://kb15a2kj75.execute-api.eu-west-1.amazonaws.com/production/getVacancyInfo', {
    "url": url
  }, {
    headers: {
      'X-Api-Key': 'HmOTl6LGjQ4x61IWpCfIY2luHukBeK8Q3JDFQh7I'
    }
  }).then(data => {
    dispatch(newMessage(data.data.message));
  }, error => {
    dispatch(newError(error.message));
  });
};