import { uuid } from './uuid';
export const ucFirst = string => {
  if (string && string !== '') {
    return string.slice(0, 1).toUpperCase() + string.slice(1);
  } else {
    return '';
  }
};
export const ucWords = string => {
  const splitString = string.split(' ');
  let modifiedString = '';
  splitString.forEach(word => {
    modifiedString += ucFirst(word) + ' ';
  });
  return modifiedString;
};
export const idFromTitle = props => {
  let id = props.prefix ?? '';
  props.title.split(' ').forEach(word => {
    id += word.toLowerCase();
  });
  id += uuid();
  return id;
};
export const formatNumber = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};