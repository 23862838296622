import * as am5 from "@amcharts/amcharts5";

export default class Am5Custom {

    customTooltip(root){
        const tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getStrokeFromSprite: false,
            autoTextColor: false,
            getLabelFillFromSprite: false,
        });

        tooltip.label.setAll({
            fill: '#fff'
        });

        const tooltipBackground = {
            fill: am5.color(0x1E025F),
            fillOpacity: 0.8,
            stroke: am5.color(0xffffff),
            strokeWidth: 2
        };
        tooltip.get("background").setAll(tooltipBackground);


        return tooltip;
    }
}

