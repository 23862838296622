/**
 * michielwouters - 06/02/2024
 * https://jobdigger.atlassian.net/wiki/spaces/~5f4df9be91e67a003f2d670f/pages/1923809283/Frontend+docs+-+helper+functions#generateClassnames
 */

export function generateClassnames(styleConfig) {
  const {
    styles,
    props,
    prefix
  } = styleConfig;
  let classNames = '';
  for (let [name, style] of Object.entries(styles)) {
    if (props.hasOwnProperty(name)) {
      style = props[name];
    }
    classNames += `${prefix}--${style} `;
  }
  return classNames;
}
export function getMapStyles() {
  return [{
    elementType: "geometry",
    stylers: [{
      color: "#a5a5a5"
    }]
  }, {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [{
      color: "#d4d4dC"
    }]
  }, {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{
      visibility: "off"
    }]
  }, {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{
      color: "#c5c5c5"
    }]
    // stylers: [{ visibility: "off" }],
  }, {
    featureType: "highway",
    elementType: "labels.icon",
    stylers: [{
      visibility: "off"
    }]
  }, {
    featureType: "water",
    elementType: "geometry",
    stylers: [{
      color: "#fefefe"
    }]
  }];
}