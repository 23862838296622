export const getPositionToParent = settings => {
  const wrapper = document.getElementById(settings.wrapperId);
  const wrapperRect = wrapper ? wrapper.getBoundingClientRect() : null;
  const element = document.getElementById(settings.elementId);
  let style = {
    position: "fixed"
  };
  if (wrapperRect) {
    switch (settings.side) {
      case "top":
        style.top = `${wrapperRect.top - element.clientHeight - settings.offset}px`;
        style.right = `${window.innerWidth - wrapperRect.right}px`;
        // style.left = `${wrapperRect.right + (wrapper.clientWidth / 2) - (element.offsetWidth / 2)}px`;
        break;
      case "right":
        style.top = `${wrapper.clientHeight / 2 - element.offsetHeight / 2 + wrapperRect.top}px`;
        style.left = `${wrapper.clientWidth + settings.offset + wrapperRect.left}px`;
        break;
      case "bottom":
        style.top = `${wrapper.clientHeight + wrapperRect.top + settings.offset}px`;
        style.left = `${wrapperRect.left + wrapper.clientWidth / 2 - element.offsetWidth / 2}px`;
        break;
      case "left":
        style.top = `${wrapper.clientHeight / 2 - element.offsetHeight / 2 + wrapperRect.top}px`;
        style.right = `${wrapperRect.right + wrapper.clientWidth + settings.offset}px`;
        break;
    }
  }
  return style;
};