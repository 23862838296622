import * as am5 from "@amcharts/amcharts5";
export function customTooltip(_ref) {
  let {
    root,
    series,
    legend = true
  } = _ref;
  let tooltip = am5.Tooltip.new(root, {
    getFillFromSprite: false,
    getStrokeFromSprite: false,
    autoTextColor: false,
    labelText: "{name}: \n {valueY}"
  });
  tooltip.get("background").setAll({
    fill: am5.color(0xffffff),
    fillOpacity: 1,
    stroke: "#9F99B1"
  });
  tooltip.label.setAll({
    fill: "#443469"
  });

  // Add an adapter to modify the tooltip's content before it is shown
  tooltip.label.adapters.add("html", function (text, target) {
    // Get the color of the hovered bar

    if (target && target._dataItem) {
      // Get the color of the series that the dataItem belongs to
      let color = target._dataItem.component.get("fill");
      let square = '<div style="margin-right: 10px; border-radius: 5px; width: 20px; height: 20px; background-color: ' + color + ';"></div>';
      const tooltiptext = series.columns.template._settings.tooltipText;
      // Prepend a colored square to the tooltip text
      return `<div style="display: flex; align-items: center;">${legend ? square : ''}<div style="display:flex;flex-direction: column; align-items: center;justify-content: center;text-align: center">${tooltiptext}</div></div>`;
    }
  });
  return tooltip;
}