export const fileDownload = (url, callback) => {
  event.preventDefault();
  let xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  // xhr.setRequestHeader('X-CSRFToken', csrftoken);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    const blob = e.currentTarget.response;
    const contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
    const fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
    saveBlob(blob, fileName);
  };
  function saveBlob(blob, fileName) {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
  }
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      callback();
      return xhr.status === 200;
    }
  };
  xhr.send();
};