import { configureStore } from '@reduxjs/toolkit';
import searchReducer from "./modules/search/searchSlice";
function counter() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'INCREMENT':
      return state + 1;
    case 'DECREMENT':
      return state - 1;
    default:
      return state;
  }
}
const store = configureStore({
  reducer: {
    counter,
    search: searchReducer
  }
});
export default store;