const React = require('react');
const ReactDOM = require('react-dom/client');
const SearchProfilesDropDown = require("./components/search_bar/SearchProfilesDropDown");
const DropDown = require('./components/menu/DropDown');
const Vacancies = require('./in_development/Vacancies');
const Modal = require('./layout/Modal');
const OrganisationLabel = require('./components/dropdown/OrganisationLabel');
const Matches = require('./pages/vacancies/Matches');
const DropdownProfileOptions = require('./components/dropdown/DropdownProfileOptions');
const OrganisationFilter = require('./components/filters/OrganisationFilter');
const MonitoringDashboard = require("./pages/monitoringtool/MonitoringDashboard");
const Yii2Form = require('./components/form/Yii2Form');
const Settings = require('./pages/Settings');
const DatapointsPageIndex = require('./pages/Datapoints/DatapointsPageIndex');
const MonitoringDashboardConcept = require("./pages/MonitoringDashboardConcept");
const DatapointsPdf = require('./pages/Datapoints/DatapointsPdf');
const Usagemonitor = require('./pages/Usagemonitor/index');
const DatapointsModal = require('./pages/Datapoints/components/DatapointsModal');
const OrganisationAnalysisFromPortfolio = require('./components/datapoints/OrganisationAnalysisFromPortfolio');
const Organisation = require('./components/organisation/Organisation');
const ShareVacanciesButton = require('./components/ShareVacanciesButton');
const OrganisationAnalysis = require('./pages/OrganisationAnalysis');
const Help = require('./pages/Help/index');
const MarketInsights = require('@components/marketinsights/MarketInsights');
const components = {
  MonitoringDashboardConcept,
  MonitoringDashboard,
  Settings,
  Vacancies,
  DatapointsPageIndex,
  OrganisationFilter,
  Modal,
  Yii2Form,
  DropDown,
  SearchProfilesDropDown,
  OrganisationLabel,
  Matches,
  DropdownProfileOptions,
  DatapointsPdf,
  DatapointsModal,
  OrganisationAnalysisFromPortfolio,
  Organisation,
  Usagemonitor,
  ShareVacanciesButton,
  OrganisationAnalysis,
  Help,
  MarketInsights
};
const App = require("./App");
const RenderComponent = (el, cp, props) => {
  const root = ReactDOM.createRoot(document.getElementById(el));
  root.render(React.createElement(App.default, JSON.parse(props), React.createElement(components[cp].default, JSON.parse(props), null)));
  return root;
};
module.exports = {
  RenderComponent
};