/**
 * michielwouters - 08/01/2025
 * @see LINK_NAAR_DOCUMENTATIE_CONFLUENCE
 */

export function checkIfObjectsEqual(object1, object2) {
  if (object1 === object2) return true;
  if (typeof object1 !== 'object' || typeof object2 !== 'object' || object1 === null || object2 === null) {
    return false;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) return false;
  for (let key of keys1) {
    if (!keys2.includes(key) || !checkIfObjectsEqual(object1[key], object2[key])) {
      return false;
    }
  }
  return true;
}